.accordion{
	.accordion-item{
		border: 1px solid rgba(142, 165, 200, 0.5);
		border-radius: 10px;
		overflow: hidden;
		margin-bottom: 20px;
		@include respond ('laptop'){
			margin-bottom: 15px;
		}
		&:last-child{
			margin-bottom: 0;			
		}	
		.accordion-header{
			.accordion-button{
				padding: 18px 25px;
				color: var(--title);
				font-weight: 500;
				font-size: 18px;
				border-radius: 10px;
				border-color: var(--primary);
				background-color: var(--card-bg);
				&:not(.collapsed){
					box-shadow: none;					
					background-color: var(--primary);
					color: #fff;
					border-radius: 0;
					&:after{
						color: #fff;
					}
				}
				&:after{
					background-image: none;
					content: "\f107";
					font-family: "Font Awesome 6 free";
					font-weight: 900;
					color: var(--primary);
					width: auto;
					height: auto;
				}				
				@include respond ('laptop'){
					padding: 12px 20px;	
				}
				@include respond ('phone'){
					font-size: 16px;
				}
			}
		}
		.accordion-body{
			background-color: var(--card-bg);
			color: var(--title);
			padding: 18px 25px;
			@include respond ('laptop'){
				padding: 12px 20px;	
			}
		}
	}
}

.dz-seprator{
	border-bottom: 1px solid #D9D9D9;
	margin: 80px 0;
	@include respond ('tab-land'){		
		margin: 40px 0px 40px 0;
	}
	@include respond ('tab-port'){		
		margin: 30px 0px 30px 0;
	}
	@include respond ('phone'){		
		margin: 30px 0px 30px 0;
	}
}